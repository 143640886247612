
import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule,provideRouter, withComponentInputBinding } from '@angular/router';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule, appRoutes } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ScriptLoaderService } from './app/core/services/script-loader.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const SHARED_MODULES = [
  RouterModule.forRoot(appRoutes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'ignore',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    enableViewTransitions: true
  }),
];

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimationsAsync(),
    importProvidersFrom(BrowserModule,BrowserAnimationsModule,AppRoutingModule, ...SHARED_MODULES, NgbModule),
    provideRouter(appRoutes, withComponentInputBinding()),
    ScriptLoaderService, { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideHttpClient(withInterceptorsFromDi()),

  ]
})
  .catch(err => console.error(err));

